import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {
  mdiBagPersonal,
  mdiEye,
  mdiEyeOff,
  mdiCheck,
  mdiCheckDecagram,
  mdiCheckDecagramOutline,
  mdiCancel,
  mdiClose,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCloseCircle,
  mdiMenuDown,
  mdiMenu,
  mdiArrowLeft,
  mdiArrowRight,
  mdiSend,
  mdiCheckboxMarkedCircleOutline,
  mdiMagnify,
  mdiViewDashboardOutline,
  mdiAccountEditOutline,
  mdiLogout,
  mdiAccount,
  mdiInformationOutline,
  mdiTextBoxSearch,
  mdiTextBoxSearchOutline,
  mdiExitToApp,
  mdiClipboardOutline,
  mdiAccountBox,
  mdiHome,
  mdiAccountCircle,
  mdiChevronUp,
  mdiChevronDown,
  mdiPlay,
  mdiRestore,
  mdiStop,
  mdiContentSave,
  mdiCloseCircleOutline,
  mdiPencil,
  mdiDelete,
  mdiCheckboxMarkedCircle,
  mdiPlus,
  mdiChevronRight,
  mdiChevronLeft,
  mdiArrowUp,
  mdiStar,
  mdiStarOutline,
  mdiFileDocument,
  mdiEmail,
  mdiImagePlus,
  mdiFile,
  mdiContentSaveAll,
  mdiFileMultiple,
  mdiUndo,
  mdiRedo,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiPoliceBadge,
  mdiCalendarMonth,
  mdiKeyboardReturn,
  mdiMinusBox,
  mdiHelpCircle,
  mdiPrinterEye,
  mdiCheckboxMultipleBlankOutline,
  mdiCheckboxMultipleMarked,
  mdiCheckboxMultipleBlank,
  mdiPrinter,
  mdiWindowClose,
  mdiPlusCircle,
  mdiSwapHorizontal,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
} from "@mdi/js";

//Usage '$vuetify.icons.xbox' or $xbox
const MY_ICONS = {
  //andy
  starOutline: mdiStarOutline,
  star: mdiStar,
  imagePlus: mdiImagePlus,
  draft: mdiFile,
  saveall: mdiContentSaveAll,
  draftall: mdiFileMultiple,
  undo: mdiUndo,
  redo: mdiRedo,
  bold: mdiFormatBold,
  italic: mdiFormatItalic,
  underline: mdiFormatUnderline,
  h1: mdiFormatHeader1,
  h2: mdiFormatHeader2,
  h3: mdiFormatHeader3,
  ul: mdiFormatListBulleted,
  ol: mdiFormatListNumbered,
  policebadge: mdiPoliceBadge,
  calendar: mdiCalendarMonth,
  return: mdiKeyboardReturn,
  help: mdiHelpCircle,
  printeye: mdiPrinterEye,
  print: mdiPrinter,
  checkalloff: mdiCheckboxMultipleBlankOutline,
  checkallon: mdiCheckboxMultipleMarked,
  checkallsome: mdiCheckboxMultipleBlank,
  windowClose: mdiWindowClose,
  plusCircle: mdiPlusCircle,
  swap: mdiSwapHorizontal,
  // jm added
  plus: mdiPlus,
  cancel1: mdiCloseCircleOutline,
  save: mdiContentSave,
  stop: mdiStop,
  loop: mdiRestore,
  play: mdiPlay,
  chevup: mdiChevronUp,
  chevdown: mdiChevronDown,
  person: mdiAccountCircle,
  person2: mdiBagPersonal,
  home: mdiHome,
  checkedcircle: mdiCheckboxMarkedCircle,
  /*Default List*/
  eye: mdiEye,
  eyeoff: mdiEyeOff,
  complete: mdiCheck,
  cancel: mdiCancel,
  close: mdiClose,
  delete: mdiCloseCircle, // delete (e.g. v-chip close)
  del: mdiDelete,
  clear: mdiClose,
  success: mdiCheck,
  successOutline: mdiCheckDecagramOutline,
  successBold: mdiCheckDecagram,
  info: mdiInformationOutline,
  warning: "...",
  error: "...",
  prev: "...",
  next: "...",
  checkboxOn: mdiCheckboxMarked,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxIndeterminate: "...",
  delimiter: "...", // for carousel
  sort: mdiArrowUp,
  expand: mdiMenuDown,
  menu: mdiMenu,
  subgroup: "...",
  dropdown: mdiMenuDown,
  radioOn: "...",
  radioOff: "...",
  edit: mdiPencil,
  ratingEmpty: "...",
  ratingFull: "...",
  ratingHalf: "...",
  loading: "...",
  first: "...",
  last: "...",
  unfold: "...",
  file: mdiFile,
  /*Additional Icons*/
  arrowLeft: mdiArrowLeft,
  arrowRight: mdiArrowRight,
  send: mdiSend,
  checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
  magnify: mdiMagnify,
  search: mdiMagnify,
  appViewDash: mdiViewDashboardOutline,
  accountEdit: mdiAccountEditOutline,
  logout: mdiLogout,
  account: mdiAccount,
  textSearch: mdiTextBoxSearch,
  textSearchOutline: mdiTextBoxSearchOutline,
  exitToApp: mdiExitToApp,
  clipboard: mdiClipboardOutline,
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: MY_ICONS,
  },
  theme: {
    themes: {
      defaultTheme: "light",
      light: {
        reportback: "#ffffff",
        background: "#172815",
        primary: "#283a44",
        secondary: "#C8D5B9",
        accent: "#708e92",
        accent2: "#7D6F80",
        error: "#f44336",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50",
        cmpbk: "#002e42",
        cmpbkinside: "#ECEFF1",
        whitesmoke: "#F5F5F5",
      },
      // join: {
      //   background: "#778899",
      //   primary: "#212121",
      //   secondary: "#C8D5B9",
      //   accent: "#6C5D2B",
      //   error: "#f44336",
      //   warning: "#ff9800",
      //   info: "#03a9f4",
      //   success: "#4caf50",
      //   cmpbk: "#D3DEDE",
      // },
    },
    options: {
      customProperties: true,
    },
  },
});
